import React from "react"
import styled from "styled-components"
import { Github, Linkedin } from "@styled-icons/fa-brands"
import { ArrowUp } from "@styled-icons/feather"

const Footer = e => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div>
      <FooterMarker>
        <hr />
        <AnimLink onClick={scrollTop}>
          <ArrowAnim size="30px" />
          <span>Top</span>
        </AnimLink>
        <hr />
      </FooterMarker>
      <FooterWrapper>
        <section>© {new Date().getFullYear()}, Karlan Astrego</section>

        <div>
          <a href="https://www.linkedin.com/in/karlanastrego/">
            <LinkedinAnim size="25px" color="#5c616c" />
          </a>
          <a href="https://github.com/astrego">
            <GithubAnim size="25px" color="#5c616c" />
          </a>
        </div>
      </FooterWrapper>
    </div>
  )
}

const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  a {
    margin: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    color: #5c616c;
  }

  section {
    margin-left: 1rem;
  }
`

const FooterMarker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  hr {
    border: none;
    height: 1px;
  }

  span {
    display: block;
  }
`

const AnimLink = styled.a`
  display: flex;
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: 300 !important;
  color: #5c616c !important;
  text-decoration: none !important;
`

const ArrowAnim = styled(ArrowUp)`
  display: block;
  transition: transform 0.2s ease-in-out;

  ${AnimLink}:hover & {
    transform: translateY(-10px);
  }
`

const LinkedinAnim = styled(Linkedin)`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
  }
`

const GithubAnim = styled(Github)`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
  }
`

export default Footer
