import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Slideshow = () => {
  const slides = [
    "I specialise in building web apps with React and Node.js",
    "I make extensively use of the JAMstack (Javascript, API & Markup)",
    "What results in fast, secure, and SEO-friendly applications",
  ]

  const [currentSlide, setCurrentSlide] = useState(slides[0])
  const [animate, setAnimate] = useState("slide fade")
  let [counter, setCounter] = useState(0)

  const showSlide = n => {
    setCurrentSlide(slides[n])
    setCounter((counter = n))
    setAnimate("slide hidden")
    setTimeout(() => {
      setAnimate("slide fade")
    }, 100)
  }

  const autoSlide = n => {
    setCurrentSlide(slides[n])

    setAnimate("slide hidden")
    setTimeout(() => {
      setAnimate("slide fade")
    }, 100)
  }

  useEffect(() => {
    const slider = () => {
      if (counter < slides.length - 1) {
        setCounter((counter = counter + 1))
        autoSlide(counter)
      } else {
        setCounter((counter = 0))
        autoSlide(counter)
      }
    }

    const interval = setInterval(slider, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const slideCurrent = slides.map((slide, index) => (
    <span
      className={index === counter ? "dot active" : "dot"}
      onClick={() => showSlide(index)}
      key={index}
    ></span>
  ))

  return (
    <div>
      <SlidesContainer>
        <div className={animate}>
          <h4>{currentSlide}</h4>
        </div>
      </SlidesContainer>
      <Navigation>{slideCurrent}</Navigation>
    </div>
  )
}

const SlidesContainer = styled.div`
  position: relative;
  margin: auto;

  @media screen and (min-width: 576px) {
    padding-top: 1.5rem;
  }

  .slide {
    display: block;

    h4 {
      padding-top: 1rem;
    }

    @media screen and (min-width: 576px) {
      h4 {
        font-size: 22px;
        padding: 0;
      }
    }
  }

  .fade {
    animation-name: fade;
    animation-duration: 10s;
    padding-top: 0px;
  }

  .hidden {
    visibility: hidden;
  }

  @keyframes fade {
    from {
      opacity: 0.05;
    }

    15% {
      opacity: 1;
    }

    85% {
      opacity: 1;
    }

    to {
      opacity: 0.05;
    }
  }
`

const Navigation = styled.div`
  text-align: center;
  padding-bottom: 1rem;

  @media screen and (min-width: 576px) {
    padding-top: 1rem;
  }

  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .active,
  .dot:hover {
    background-color: #e1a73b;
  }
`

export default Slideshow
