import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import StyledBackgroundSection from "./background-section"

import Slideshow from "./slideshow"

const Header = ({ siteTitle }) => (
  <StyledBackgroundSection>
    <StyledHeader>
      <StyledLogo>
        <span>K</span>A
      </StyledLogo>
      <SocialWrapper>
        <li>
          <a href="https://www.linkedin.com/in/karlanastrego/">LinkedIn</a>
        </li>
        <li>
          <a href="https://github.com/astrego">GitHub</a>
        </li>
      </SocialWrapper>
    </StyledHeader>

    <StyledDiv>
      <h1>Karlan Astrego</h1>
      <h2 className="sub-title">- full stack web developer - </h2>
      <Slideshow />
    </StyledDiv>
  </StyledBackgroundSection>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledLogo = styled.h1`
  color: white;
  font-weight: 400;
  margin: 1rem;

  span {
    display: inline-block;
    transform: rotateY(180deg);
  }
`

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledDiv = styled.div`
  width: 100%;
  height: 250px;

  @media screen and (min-width: 576px) {
    height: 290px;

    .sub-title {
      font-size: 39px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
    text-align: center;
    margin: 0px;
  }

  h2 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1rem;
    padding: 0 1rem 1rem 1rem;
  }
`

const SocialWrapper = styled.ul`
  list-style-type: none;
  height: auto;
  display: flex;
  margin-right: 1rem;

  a {
    display: block;
    color: white;
    font-size: 1rem;
    margin: 0 0.5rem;
  }

  a:hover {
    color: grey;
    text-decoration: none;
  }
`

export default Header
